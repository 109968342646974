@font-face {
  font-family: 'butler-light';
  src: url('./fonts/Butler_Light_Stencil.otf')
}

@font-face {
  font-family: 'butler-regular';
  src: url('./fonts/Butler_Regular_Stencil.otf')
}

//font for indie flower
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

//font for Montserrat, sans-serif
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Montserrat:wght@100;300;600&display=swap');


@import './styles/base/reset.scss';
@import './styles/animation/Transition.scss';

$color1: #EEE7E1;
$color2: white;
$color3: #271A26;

.homepage-wrapper {
  height: 100%;
}

.homepage-container {
  background-color: $color1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-wrapper {
    display: flex;
    flex-direction: row;

    background-color: $color2;
    width: 65%;
    height: 100%;
    margin: 8vh auto 2vh auto;
    padding: 2em;
    border-radius: 20px;
    box-shadow: 0px 20px 50px 0px rgba(163, 163, 163, 0.365);

    .main-body-article {
      height: 90%;
      width: 90%;
      margin: 0 4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.main-cover {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100vw;
  background-color: rgb(29, 25, 29);

  animation: shrinkDownTop 1s;
}

.resume-cover {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100vw;
  background-color: rgb(29, 25, 29);

  animation: shrinkTopDown 1s;
}

.about-cover {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(29, 25, 29);

  animation: shrinkLeftRight 1s;
  animation-fill-mode: forwards;
}


@media only screen and (max-width: 414px) {

  .homepage-container {
    background-color: $color1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .main-wrapper {
      display: flex;
      flex-direction: row;

      background-color: $color2;
      width: calc(90vw - 2rem);
      height: 100%;
      margin: 4vh 5vw 2vh 5vw;
      padding: 1em;
      border-radius: 20px;
      box-shadow: 0px 20px 50px 0px rgba(163, 163, 163, 0.365);

      .main-body-article {
        height: 90%;
        width: 90%;
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
}