
.wt-btn_sign {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: -.21px;
  white-space: nowrap;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s;
  line-height: 20px;
  border: none;
  height: 45px;
  width: 100%;
  padding: 0 5px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;

  transition: 0.5s;

}

.wt-btn_sign:hover {
  filter: invert(1);
  transition: 0.5s;
}

.wt-btn_sign-bound {
  display: block;
  overflow: hidden;
}

:hover.wt-btn_sign-bound span {
  position: relative;
  display: inline-block;
  padding: 0 22px;
  // -webkit-animation: wt-btn_sign-marquee 2s linear infinite;
  // -moz-animation: wt-btn_sign-marquee 2s linear infinite;
  // -o-animation: wt-btn_sign-marquee 2s linear infinite;
  animation: wt-btn_sign-marquee 2s linear infinite;
  cursor: pointer;
}

:hover.wt-btn_sign-bound span:after {
  content: attr(data-text);
  position: absolute;
  left: 100%;
  padding: 0 23px;
}

@-webkit-keyframes wt-btn_sign-marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@-moz-keyframes wt-btn_sign-marquee {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@-o-keyframes wt-btn_sign-marquee {
  0% {
    transform: translate3d(0, 0, 0)
  }

  100% {
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes wt-btn_sign-marquee {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}



@media only screen and (max-width: 414px) {
  .wt-btn_sign {
      font-size: 10px;
      height: 30px;
  }
}