.article-block-1-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;

  animation: blurToClear 1.3s;

  .intro-container-1 {

    .large-title {
      margin: none;
      font-size: 190px;
      font-family: 'butler-regular';
      letter-spacing: 10px;

      animation: fadeInFromBot 1s;
    }

    .intro-inner-container {
      width: 100%;

      .intro-nickname {
        display: inline-block;
        margin: 0;
        font-size: 40px;
        font-family: 'butler-regular';
      }

      .intro-one-liner-1 {
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        animation: typing 1.6s steps(25, end) forwards;
        animation-delay: 0.6s;
        width: 0;

        font-size: 33px;
        font-family: 'butler-light';
      }

      .intro-one-liner-2 {
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        animation: typing 1.3s steps(25, end) forwards;
        animation-delay: 2.0s;
        width: 0;

        font-size: 33px;
        font-family: 'butler-light';
      }
    }
  }

  .intro-container-2 {
    margin-top: 45px;

    .intro-fullname {
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
      opacity: 0;

      animation: transitionIn 1.5s;
      animation-delay: 3s;
      animation-fill-mode: forwards;
    }

    .detailed-discription {
      text-align: justify;
      line-height: 25px;
      width: 520px;
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      opacity: 0;

      animation: transitionIn 1s;
      animation-delay: 3s;
      animation-fill-mode: forwards;
    }
  }

  .click-me-sign {
    position: absolute;
    top: 420px;
    font-size: 60px;
    font-family: 'Indie Flower', cursive;
    animation: fadeInFromBot 0.8s;

    &:hover {
      cursor: auto;
    }
  }
}

.front {
  position: relative;
  display: flex;
  justify-content: center;
  top: 40px;
  width: 330px;
  border: 5px solid black;
  border-radius: 12px;
  background-color: #C5B9A6;
  opacity: 0;
  backface-visibility: hidden;
  animation: fadeInFromRight 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  &:hover {
    cursor: pointer;
  }

  .card-body {
    height: 400px;
    width: 400px;
  }
}

.back {
  display: flex;
  justify-content: center;
  width: 330px;
  border: 5px solid black;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }

  .card-body {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}



@media only screen and (max-width: 414px) {
  .article-block-1-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;


    .intro-container-1 {

      .large-title {
        font-size: 80px;
        letter-spacing: 5px;
      }

      .intro-inner-container {
        width: 100%;

        .intro-nickname {
          font-size: 30px;
        }

        .intro-one-liner-1 {
          font-size: 14px;
          font-family: 'butler-light';
        }

        .intro-one-liner-2 {
          overflow: hidden;
          white-space: nowrap;
          margin: 0;
          animation: typing 1.3s steps(25, end) forwards;
          animation-delay: 2.0s;
          width: 0;

          font-size: 14px;
          font-family: 'butler-light';
        }
      }
    }

    .intro-container-2 {
      margin-top: 15px;

      .intro-fullname {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        opacity: 0;

        animation: transitionIn 1.5s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
      }

      .detailed-discription {
        text-align: justify;
        line-height: 20px;
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        opacity: 0;

        animation: transitionIn 1s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
      }
    }

    .click-me-sign {
      position: absolute;
      top: 190px;
      font-size: 16px;
      font-family: 'Indie Flower', cursive;
      animation: fadeInFromBot 0.8s;

      &:hover {
        cursor: auto;
      }
    }
  }

  .front {
    position: relative;
    left: 15%;
    height: 180px;
    width: 150px;
    border: 2px solid black;

    .card-body {
      height: 180px;
    }
  }

  .back {
    position: relative;
    top: 40px;
    left: 15%;
    height: 180px;
    width: 150px;
    border: 2px solid black;

    .card-body {
      height: 180px;
      border-radius: 12px;
    }
  }
}