.article-block-5-container {
  position: relative;
  height: 23em;
  animation: blurToClear 1.3s;
  margin-bottom: 80px;

  .section-title {
    margin: 0.5em 0 1em 0;
    font-size: 40px;
    font-family: "butler-regular";
    opacity: 0;
    animation: fadeInFromTop 1s;

    animation-fill-mode: forwards;
    animation-delay: 0.6s;
  }

  .timeline-resume-wrapper {
    height: 70%;
    margin-top: 3em;
    display: flex;
    flex-direction: row;
  }

  .timeline-container {
    width: 70%;

    .dot-container {
      height: 100%;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .click-me-sign {
        position: absolute;
        top: 20%;
        left: 20%;
        rotate: -5deg;
        font-size: 35px;
        font-family: 'Indie Flower', cursive;        
      }


      .line {
        position: absolute;
        height: 3px;
        width: 100%;
      }
      .arrow {
        display: none;
        position: absolute;
        left: 95%;
        width: 0; 
        height: 0; 
        border-top: 15px solid transparent !important;
        border-bottom: 15px solid transparent !important;
        
        border-left: 15px solid rgb(126, 126, 126);
      }

      .dot-inner-wrapper {

        .dot {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: red;
          height: 1.2em;
          width: 1.2em;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }

          .timeline-content-wrapper-up {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 11em;
            animation: shortFadeFromBot 0.5s;
            animation-fill-mode: forwards;

            .timeline-content-up {
              height: 130px;
              width: 210px;
              border-radius: 24px;
              border: 3px dashed;
              padding: 0.2em 0.8em;
              word-wrap: break-word;

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
            }

            .dot-connect-line {
              width: 3px;
              height: 18px;
            }
          }

          .timeline-content-wrapper-down {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 11em;
            animation: shortFadeFromTop 0.5s;
            animation-fill-mode: forwards;

            .timeline-content-down {
              height: 130px;
              width: 210px;
              border-radius: 24px;
              border: 3px dashed;
              padding: 0.2em 0.8em;
              word-wrap: break-word;

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
            }

            .dot-connect-line {
              width: 3px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  .resume-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

  }
}


.timeline-date {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.timeline-location {
  font-size: 21px;
  font-family: "butler-regular";
  font-weight: 600;
}
.timeline-event {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.timeline-img {
  height: 40px;
}

.svg {
 margin: 0 0.5em;
}




@keyframes shortFadeFromBot {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes shortFadeFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@media only screen and (max-width: 414px) {

  .article-block-5-container {
    position: relative;
    height: 23em;
    animation: blurToClear 1.3s;
    margin-bottom: 80px;
  
    .section-title {
      margin: 0.5em 0 1em 0;
      font-size: 40px;
      font-family: "butler-regular";
      opacity: 0;
      animation: fadeInFromTop 1s;
  
      animation-fill-mode: forwards;
      animation-delay: 0.6s;
    }
  
    .timeline-resume-wrapper {
      height: 70%;
      margin-top: 3em;
      display: flex;
      flex-direction: row;
    }
  
    .timeline-container {
      width: 70%;
      display: none;
  
      .dot-container {
        height: 100%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
  
        .click-me-sign {
          position: absolute;
          top: 20%;
          left: 20%;
          rotate: -5deg;
          font-size: 35px;
          font-family: 'Indie Flower', cursive;        
        }
  
  
        .line {
          position: absolute;
          height: 3px;
          width: 600px;
        }
        .arrow {
          position: absolute;
          left: 89%;
          width: 0; 
          height: 0; 
          border-top: 15px solid transparent !important;
          border-bottom: 15px solid transparent !important;
          
          border-left: 15px solid rgb(126, 126, 126);
        }
  
        .dot-inner-wrapper {
  
          .dot {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: red;
            height: 1.2em;
            width: 1.2em;
            border-radius: 50%;
  
            &:hover {
              cursor: pointer;
            }
  
            .timeline-content-wrapper-up {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 11em;
              animation: shortFadeFromBot 0.5s;
              animation-fill-mode: forwards;
  
              .timeline-content-up {
                height: 130px;
                width: 210px;
                border-radius: 24px;
                border: 3px dashed;
                padding: 0.2em 0.8em;
                word-wrap: break-word;
  
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
              }
  
              .dot-connect-line {
                width: 3px;
                height: 18px;
              }
            }
  
            .timeline-content-wrapper-down {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 11em;
              animation: shortFadeFromTop 0.5s;
              animation-fill-mode: forwards;
  
              .timeline-content-down {
                height: 130px;
                width: 210px;
                border-radius: 24px;
                border: 3px dashed;
                padding: 0.2em 0.8em;
                word-wrap: break-word;
  
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
              }
  
              .dot-connect-line {
                width: 3px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  
    .resume-container {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

    }
  }
  
  
  .timeline-date {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }
  .timeline-location {
    font-size: 21px;
    font-family: "butler-regular";
    font-weight: 600;
  }
  .timeline-event {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
  }
  .timeline-img {
    height: 40px;
  }
  
  .svg {
   margin: 0 0.5em;
  }
  
  
  
  
}