#root {
  height: 100%;
}

.darkMode {
  filter: invert(1) hue-rotate(180deg);
}
.inverted {
  filter: invert(1) hue-rotate(180deg);
}

body {
  padding: 0px;
  margin: 0px;
  height: fit-content;
  box-sizing: border-box;

  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
