@use '../colors/color.scss' as *;



.light {

  .text {
    color: $font-light !important;
  }
  .text-reverse {
    color: $font-dark !important;
  }
  .line {
    background-color: $font-light !important;
  }
  .background {
    background-color: $background-light !important;
  }
  .background-reverse {
    background-color: $background-dark !important;
  }
  .background-secondary {
    background-color: $background-secondary-light !important;
  }
  .background-tertiary {
    background-color: $background-tertiary-light !important;
  }
  .border {
    border-color: $font-light !important;;
  }
  .border-reverse {
    border-color: $font-dark !important;;
  }
}

.dark {

  .text {
    color: $font-dark !important;
  }
  .text-reverse {
    color: $font-light !important;
  }
  .line {
    background-color: $font-dark !important;
  }
  .background {
    background-color: $background-dark !important;
  }
  .background-reverse {
    background-color: $background-secondary-light !important;
  }
  .background-secondary {
    background-color: $background-secondary-dark !important;
  }
  .background-tertiary {
    background-color: $background-tertiary-dark !important;
  }
  .border {
    border-color: $font-dark !important;;
  }
  .border-reverse {
    border-color: $font-light !important;;
  }
}

