.article-block-6-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;

  animation: blurToClear 1.3s;

  .wrapper {
    margin: 1em 0 0.25em 0;
    box-sizing: content-box;
    font-size: 160px;
    font-family: 'butler-light';
    letter-spacing: 10px;
    transform: scaleY(1.2);
    display: flex;
    height: 200px;

    animation: fadeInFromBot 1s;

    .words {
      overflow: hidden;

      span {
        display: block;
        height: 100%;
        animation: spin_words 10s infinite;
      }
    }
  }

  .quote-block-container {
    margin: 12em 0 1em 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;

    .quote-block {
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
      margin: 5px 0;
    }
    .quote-author {
      font-size: 18px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
  }
  .contact-details {
    margin-bottom: 15em;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    
    div {
      margin: 0.5em 0;
    }
  }
}



@keyframes spin_words {
  10% {
    transform: translateY(-0%);
  }

  25% {
    transform: translateY(-212%);
  }

  35% {
    transform: translateY(-400%);
  }

  50% {
    transform: translateY(-580%);
  }

  60% {
    transform: translateY(-800%);
  }

  75% {
    transform: translateY(-970%);
  }

  // 85% {
  //   transform: translateY(-412%);
  // }

  // 100% {
  //   transform: translateY(-400%);
  // }
}


@media only screen and (max-width: 414px) {
  
  .article-block-6-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
  
    animation: blurToClear 1.3s;
  
    .wrapper {
      margin: 1em 0 0.25em 0;
      box-sizing: content-box;
      font-size: 30px;
      font-family: 'butler-light';
      letter-spacing: 10px;
      transform: scaleY(1.2);
      display: flex;
      height: 80px;
  
      animation: fadeInFromBot 1s;
  
      .words {
        overflow: hidden;
  
        span {
          display: block;
          height: 100%;
          animation: spin_words 10s infinite;
        }
      }
    }
  
    .quote-block-container {
      margin: 6em 0 1em 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: fit-content;
  
      .quote-block {
        font-size: 10px;
        font-family: 'Montserrat', sans-serif;
        margin: 2.5px 0;
      }
      .quote-author {
        font-size: 8px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
      }
    }
    .contact-details {
      margin-bottom: 15em;
      font-size: 10px;
      font-family: 'Montserrat', sans-serif;
      
      div {
        margin: 0.5em 0;
      }
    }
  }
  
  @keyframes spin_words {
    10% {
      transform: translateY(-0%);
    }
  
    25% {
      transform: translateY(-140%);
    }
  
    35% {
      transform: translateY(-260%);
    }
  
    50% {
      transform: translateY(-400%);
    }
  
    60% {
      transform: translateY(-540%);
    }
  
    75% {
      transform: translateY(-680%);
    }
  
    // 85% {
    //   transform: translateY(-412%);
    // }
  
    // 100% {
    //   transform: translateY(-400%);
    // }
  }

}