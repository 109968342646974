$color1: #EEE7E1;
$color2: white;
$font-primary: #342432;

@import './base/reset.scss';

.side-nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;

  .logo {
    height: 100px;
    width: 100px;
    filter: none !important;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-connection-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    height: 100%;

    .side-nav-line {
      width: 1px;
      height: 100%;
      margin-bottom: 2rem;
      margin-top: 2rem;
      background-color: #1a1919;
    }

    .small-icon {
      color: $font-primary;
      font-size: 30px;
      margin-top: 1rem;
      margin-bottom: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
    

  }

}


@media only screen and (max-width: 414px) {

  .side-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
  
    .logo {
      height: 40px;
      width: 40px;
      filter: none !important;
  
      &:hover {
        cursor: pointer;
      }
    }
  
    .nav-connection-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
      height: 100%;
  
      .side-nav-line {
        width: 1px;
        height: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;
        background-color: #1a1919;
      }
  
      .small-icon {
        color: $font-primary;
        font-size: 20px;
        margin-top: 1rem;
        margin-bottom: 1rem;
  
        &:hover {
          cursor: pointer;
        }
      }
      
  
    }
  
  }
  

}