footer {
  position: relative;
  height: 15vh;
  width: 65vw;
  margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .copyright {
    font-family: 'Montserrat', sans-serif;
  }

  .foooter-nav {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    transition: 0.5s;


    .side-nav-line-L {
      height: 1px;
      width: 100%;
      margin: 0 2em;
      background-color: #1a1919;
    }
    .button {
      &:hover {
        cursor: pointer;
        transform: rotate(-5deg);
        transition: 0.5s;
      }
    }
  }
}


@media only screen and (max-width: 414px) {

  footer {
    position: relative;
    height: 15vh;
    width: 80vw;
    margin: auto;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .copyright {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
    }
  
    .foooter-nav {
      width: 20%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      transition: 0.5s;
      font-size: 10px;

  
  
      .side-nav-line-L {
        height: 1px;
        width: 100%;
        margin: 0 2em;
        background-color: #1a1919;
      }
      .button {
        &:hover {
          cursor: pointer;
          transform: rotate(-5deg);
          transition: 0.5s;
        }
      }
    }
  }

}