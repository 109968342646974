@keyframes transitionIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes blurToClear {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
}

@keyframes fadeInFromBot {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes shrinkDownTop {
  from {
    height: 100vh;
    top: 0;
  }
  to {
    height: 0vh;
    top: 100vh;
  }
}

@keyframes shrinkTopDown {
  from {
    height: 100vh;
  }
  to {
    height: 0vh;
  }
}

@keyframes shrinkLeftRight {
  from {
    width: 100vw;
    left: 0;
  }
  to {
    width: 0vw;
    left: 100%;
  }
}


@keyframes typing {
  from {width: 0%; 
  }
  to {width: 100%; 
  }
}



