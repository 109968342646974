.buttons {
  height: 90px;
  width: 90%;
  margin: 15px 0;
  text-align: center;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 10px 0px rgba(163, 163, 163, 0.158);

  a {
    text-decoration: none;
  }
}

$primary: #753f6c;
$dark: #ffffff;
$borderW: 2px;

.blob-btn {
  $numOfBlobs: 4;
  z-index: 1;
  position: relative;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  color: $primary;
  font-size: 25px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 24px;

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }

  &:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: $borderW*1.5;
    top: $borderW*1.5;
    width: 100%;
    height: 100%;

    transition: all 0.3s 0.2s;
    border-radius: 24px;
  }

  &:hover {
    color: $dark;
    border-radius: 24px;

    &:after {
      transition: all 0.3s;
      left: 0;
      top: 0;
      border-radius: 24px;
    }
  }

  &__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: #ffffff;
  }

  // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
  &__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url('#goo');
  }

  &__blob {
    position: absolute;
    top: $borderW;
    width: 100% / $numOfBlobs;
    height: 100%;
    background: $primary;
    border-radius: 2400%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.8s;

    @supports(filter: url('#goo')) {
      transform: translate3d(0, 150%, 0) scale(1.4);
    }

    @for $i from 1 through $numOfBlobs {
      &:nth-child(#{$i}) {
        left: ($i - 1) * (120% / $numOfBlobs);
        transition-delay: ($i - 1) * 0.08s;
      }
    }

    .blob-btn:hover & {
      transform: translateZ(0) scale(1.7);

      @supports(filter: url('#goo')) {
        transform: translateZ(0) scale(1.4);
      }
    }
  }

}

@media only screen and (max-width: 414px) {
  .buttons {
    height: 60px;
    width: 90%;
    margin: 20px 0;
    text-align: center;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 10px 0px rgba(163, 163, 163, 0.158);
  
    a {
      text-decoration: none;
    }
  }
  

  .blob-btn {
    $numOfBlobs: 4;
    z-index: 1;
    position: relative;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    color: $primary;
    font-size: 20px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 24px;

    &:before {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }

    &:after {
      content: "";
      z-index: -2;
      position: absolute;
      left: $borderW*1.5;
      top: $borderW*1.5;
      width: 100%;
      height: 100%;

      transition: all 0.3s 0.2s;
      border-radius: 24px;
    }

    &:hover {
      color: $dark;
      border-radius: 24px;

      &:after {
        transition: all 0.3s;
        left: 0;
        top: 0;
        border-radius: 24px;
      }
    }

    &__inner {
      z-index: -1;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: #ffffff;
    }

    // additional container created, because in FF blobs are breaking overflow:hidden of element with svg gooey filter
    &__blobs {
      position: relative;
      display: block;
      height: 100%;
      filter: url('#goo');
    }

    &__blob {
      position: absolute;
      top: $borderW;
      width: 100% / $numOfBlobs;
      height: 100%;
      background: $primary;
      border-radius: 2400%;
      transform: translate3d(0, 150%, 0) scale(1.7);
      transition: transform 0.8s;

      @supports(filter: url('#goo')) {
        transform: translate3d(0, 150%, 0) scale(1.4);
      }

      @for $i from 1 through $numOfBlobs {
        &:nth-child(#{$i}) {
          left: ($i - 1) * (120% / $numOfBlobs);
          transition-delay: ($i - 1) * 0.08s;
        }
      }

      .blob-btn:hover & {
        transform: translateZ(0) scale(1.7);

        @supports(filter: url('#goo')) {
          transform: translateZ(0) scale(1.4);
        }
      }
    }

  }
}