.article-block-4-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin-top: 20px;

  .section-title {
    margin: 25px 0;
    text-align: end;
    font-size: 50px;
    font-family: "butler-regular";
    animation: fadeInFromTop 1s;
  }

  .inner-container {
    display: inline-flex;
    position: relative;
    height: 220px;
    margin-bottom: 3.1em;
    border-radius: 20px;
    border: 5px solid rgb(65, 47, 66);
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      box-shadow:
        rgb(77, 60, 74) 7px 7px,
        rgba(219, 203, 215, 0.5) 12px 12px,
    }

    .image-card-left {
      position: relative;
      z-index: 1;
      margin: 0;
      height: 100%;
      width: 100%;
      border-radius: 18px;
      transition: 0.5s;
      filter: grayscale(80%);

      &:hover {
        cursor: pointer;
        filter: grayscale(0);
      }

      .gif-showcase {
        border-radius: 18px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .description-card-right {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      left: 47.75%;
      margin: 0;
      height: 220px;
      width: 500px;
      border-radius: 0 50px 50px 0;
      transition: 1s;
    }

    .image-card-right {
      position: relative;
      left: 0%;
      z-index: 1;
      margin: 0;
      height: 100%;
      width: 100%;
      border-radius: 18px;
      transition: 0.5s;
      filter: grayscale(80%);

      &:hover {
        cursor: pointer;
        filter: grayscale(0);
      }

      .gif-showcase {
        border-radius: 18px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .description-card-left {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      height: 220px;
      width: 500px;
      border-radius: 50px 0 0 50px;
      transition: 1s;
    }

    .button-wrapper {
      border-radius: 24px;
      height: 35px;
      width: 150px;
      margin: 1em auto;
      display: flex;
      justify-content: center;
      align-items: center;

      animation: relax 1s;

      .button-to-project {
        font-size: 25px;
        font-family: "butler-regular";
        text-decoration: none;
        text-align: center;
        color: inherit;
        height: 25px;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        animation: stretch 1s;
        animation-fill-mode: forwards;
      }
    }
  }
}

.project-description-container {
  margin: 0.5em 2em 0.5em 2em;
  width: 55%;

  .project-title {
    font-size: 25px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }

  .project-description {
    margin-top: 0.5em;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    text-align: start;
  }
}


.hover-left {
  width: 65% !important;
  transition: 0.5s !important;
  cursor: pointer;
}


.hover-right {
  width: 65% !important;
  ;
  left: 35% !important;
  ;
  transition: 0.5s !important;
  ;
  cursor: pointer;
}


.s1 {
  animation: fadeInFromRight 1s;
  animation-fill-mode: forwards;
}

.s2 {
  animation: fadeInFromLeft 2s;
  animation-fill-mode: forwards;
}

.s3 {
  animation: fadeInFromRight 2.75s;
  animation-fill-mode: forwards;
}

.s4 {
  animation: fadeInFromLeft 3.5s;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 414px) {
  .article-block-4-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin-top: 20px;

    .section-title {
      margin: 25px 1rem;
      text-align: end;
      font-size: 50px;
      font-family: "butler-regular";
      animation: fadeInFromTop 1s;
    }

    .inner-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      position: relative;
      height: fit-content;
      margin-bottom: 3.1em;
      border-radius: 20px;
      border: 5px solid rgb(65, 47, 66);
      transition: 0.5s;

      &:nth-of-type(even) {
        flex-direction: column-reverse;
      }

      &:hover {
        transition: 0.5s;
        box-shadow:
          rgb(77, 60, 74) 7px 7px,
          rgba(219, 203, 215, 0.5) 12px 12px,
      }

      .image-card-left {
        position: relative;
        z-index: 1;
        margin: 0;
        height: 100%;
        width: 100%;
        border-radius: 18px;
        transition: 0.5s;
        filter: grayscale(80%);

        &:hover {
          cursor: pointer;
          filter: grayscale(0);
        }

        .gif-showcase {
          border-radius: 18px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .description-card-right {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        left: 0%;
        margin: 0;
        height: auto;
        width: 100%;
        border-radius: 0 50px 50px 0;
        transition: 1s;
      }

      .image-card-right {
        position: relative;
        left: 0%;
        z-index: 1;
        margin: 0;
        height: 100%;
        width: 100%;
        border-radius: 18px;
        transition: 0.5s;
        filter: grayscale(80%);

        &:hover {
          cursor: pointer;
          filter: grayscale(0);
        }

        .gif-showcase {
          border-radius: 18px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .description-card-left {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        height: 100%;
        width: 100%;
        border-radius: 50px 0 0 50px;
        transition: 1s;
      }

      .button-wrapper {
        border-radius: 24px;
        height: 25px;
        width: 150px;
        margin: 1em auto;
        display: flex;
        justify-content: center;
        align-items: center;

        animation: relax 1s;

        .button-to-project {
          font-size: 20px;
          font-family: "butler-regular";
          text-decoration: none;
          text-align: center;
          color: inherit;
          height: 25px;
          width: 100%;
        }

        &:hover {
          cursor: pointer;

          animation: stretch 1s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .project-description-container {
    // display: none;
    margin: 0;
    padding: 1rem;
    width: calc(100% - 2rem);

    .project-title {
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .project-description {
      margin-top: 0.5em;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      text-align: start;
    }
  }
}