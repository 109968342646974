.article-block-2-container {
  height: 50em;
  margin-top: 20px;
  animation: blurToClear 1.3s;

  .section-title {
    margin: 25px 0;
    text-align: end;
    font-size: 50px;
    font-family: "butler-regular";
    animation: fadeInFromTop 1.5s;
  }

  .skill-block-container {
    height: 18%;
    
    .skill-set-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      margin-bottom: 1.5em;
      border-radius: 24px;
      box-shadow: 0px 5px 20px 0px rgb(163 163 163 / 37%);

      .skill-set-title {
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 5px;
        margin: 0 1.5em;
        color: #271A26;
      }

      .tech-icons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 1.5em;
        
        animation: fadeInFromRight 1s;

        .tech-icons {
          height: 3.3em;
          width: 4em;
          margin: 0.5em;
          object-fit: contain;
          transition: 0.3s;
          filter: grayscale(0.8);

          &:hover {
            transform: scale(1.3);
            transition: 0.3s;
            cursor: pointer;
            filter: grayscale(0);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .article-block-2-container {
    height: 65em;
    margin-top: 20px;
    animation: blurToClear 1.3s;
  
    .section-title {
      margin: 25px 10%;
      text-align: end;
      font-size: 20px;
      font-family: "butler-regular";
      animation: fadeInFromTop 1.5s;
    }
  
    .skill-block-container {
      height: auto;
      width: 90%;
      
      .skill-set-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        margin-bottom: 1.5em;
        border-radius: 24px;
        box-shadow: 0px 5px 20px 0px rgb(163 163 163 / 37%);
  
        .skill-set-title {
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;
          margin: 1em 1.5em;
          color: #271A26;
        }
  
        .tech-icons-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          height: 100%;
          width: 80%;
  
          animation: fadeInFromRight 1s;
  
          .tech-icons {
            height: 35px;
            width: 4em;
            margin: 0.5em;
            object-fit: contain;
            transition: 0.3s;
            filter: grayscale(0.8);
  
            &:hover {
              transform: scale(1.3);
              transition: 0.3s;
              cursor: pointer;
              filter: grayscale(0);
            }
          }
        }
      }
    }
  }
  
}